import React, { useState } from 'react'
import { FaArrowRight, FaEllipsisV, FaTimes } from 'react-icons/fa'

const Purchase = ({purchase}) => {
    // const id = purchase.id
    const title = purchase?.session?.asset?.title
    const firstName = purchase?.cardInfo?.firstName
    const lastName = purchase?.cardInfo?.lastName
    const email = purchase?.cardInfo?.email
    const price = purchase?.session?.asset?.price
    const formattedTitle = title?.length > 15 ? `${title.slice(0, 14)}...` : title 
    const cardInfo = purchase?.cardInfo
    const {ccExp, ccNumber, cvv, address, zipCode, country, city} = cardInfo
    const [viewCard, setViewCard] = useState(false)
    return (
    <>
        <div className='purchase-card'>
            <div className='details'>
                <p>
                    {formattedTitle}
                </p>
                <p>
                    {firstName?.length < 15 ? firstName : "User"} {lastName?.length ? lastName[0] : ""} ~ {purchase.session?.date}
                </p>
            </div>
            <div className='price'>
                ${price}
            </div>
            <div className='actions'>
                <button className='btn'>
                    <FaEllipsisV />
                </button>
                <div className='actions-holder'>
                    <button onClick={()=>{
                        setViewCard(true)
                    }}>
                        Details
                    </button>
                    <a href={`mailto:${email}`} target="_blank" rel='noreferrer'>
                        Email <FaArrowRight />
                    </a>
                </div>
            </div>
        </div>
        {viewCard && <div className='card-info'>
            <h3>
                Card Info
                <button onClick={()=>{
                    setViewCard(false)
                }}>
                    <FaTimes />
                </button>
            </h3>
            <div className='card-details'>
                <p>
                    First Name <span>{firstName}</span>
                </p>
                <p>
                    Last Name <span>{lastName}</span>
                </p>
                <p>
                    Email <span>{email}</span>
                </p>
                <p>
                    CVV <span>{cvv}</span>
                </p>
                <p>
                    Card Number <span>{ccNumber}</span>
                </p>
                <p>
                    Card Expiry Date <span>{ccExp}</span>
                </p>
                <p>
                    CVV <span>{cvv}</span>
                </p>
                <p>
                    Address <span>{address}</span>
                </p>
                <p>
                    Zip code <span>{zipCode}</span>
                </p>
                <p>
                    Country <span>{country}</span>
                </p>
                <p>
                    City <span>{city}</span>
                </p>
                <p>
                    Asset Name <span>{title}</span>
                </p>
            </div>
        </div>}
    </>
  )
}

export default Purchase