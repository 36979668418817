import React, { useEffect, useRef, useState } from 'react'
import {FaArrowLeft, FaSearch, FaTimes} from 'react-icons/fa'
import img4 from "../../assets/img-iv.jpg"
// import img5 from "../../assets/img-v.jpg"
// import img6 from "../../assets/img-vi.jpg"
// import img7 from "../../assets/img-vii.jpg"
// import img8 from "../../assets/img-viii.jpg"
// import img9 from "../../assets/img-ix.jpg"
import IconsHolder from '../../components/iconsHolder/IconsHolder'
import PaymentPage from '../../components/paymentPage/PaymentPage'
// import { PaystackButton } from 'react-paystack'
import { addDoc, collection, getDocs } from 'firebase/firestore'
import { db } from '../../api/firebase'
import Notification from '../../components/notification/Notification'
// import img5 from "../../assets/img-v.jpg"

const Shop = ({closeMenu}) => {

  const [isAdding, setIsAdding] = useState(false)

  const [search, setSearch] = useState("")
  const [ finalStage, setFinalStage ] = useState(false)
  const [ hasFetchedStore, setHasFetchedStore ] = useState(false)
  const [assets, setAssets] = useState([])

  const handleChange = (e)=>{
    setSearch(e.target.value)
  }

  const form = useRef(null)
  const formBtn = useRef(null)

  const handleSubmit = (e)=>{
    e.preventDefault()
    form.current.blur()
    formBtn.current.blur()
  }

  const filteredAssets = assets.filter((asset)=>{
    const title = asset.title.toLowerCase()
    const description = asset.description.toLowerCase()
    const searchTerm = search.toLowerCase()
    return title.includes(searchTerm) || description.includes(searchTerm)
  })

  const [currentState, setCurrentState] = useState({
    details: {
      emailAddress: "",
    },
    showing: "user-details",
    asset: {
      title: "Car in 3d ~ assets, textures and character templates",
      price: 500,
      imgUrl: img4,
      description: `Purchase up to Indie game ~ assets, textures and character templates`
    },
    date: "",
  })

  const [email, setEmail] = useState({
    email: "",
    confirmEmail: ""
  })

  const [makePurchase, setMakePurchase] = useState(false)

  const [notificationDetails, setNotificationDetails] = useState({
    message: ``,
    type: "normal",
    isOpen: false,
  })


  const newNotification = (message, type)=>{
    setNotificationDetails({
      message,
      type,
      isOpen: true,
    })
  }

  const closeNotification = ()=>{
    setNotificationDetails((prev)=>{
      return ({
        ...prev,
        isOpen: false
      })
    })

    setTimeout(()=>{
      setNotificationDetails((prev)=>{
        return ({
          message: ``,
          type: "normal",
          isOpen: false
        })
      })
    }, 700)
  }

  const end = ()=>{
    try{
      setMakePurchase(false)
      setFinalStage(false)
      setCurrentState((prev)=>{
        return ({
          ...prev,
          showing: "user-details",
        })
      })
    } catch(error){
      console.error(error)
    }
  }

  const storeCollectionRef = collection(db, "store")

  useEffect(()=>{
    const getstore = async()=>{
      try{
        const data = await getDocs(storeCollectionRef);
        const filteredData = data.docs.map((doc)=>{
          return ({
            ...doc.data(),
            id: doc.id,
          })
        })
        if(filteredData){
          setAssets([...filteredData])
          setHasFetchedStore(true)
      }
    } catch(err){
            console.error(err)
            setHasFetchedStore(true)
        }
    }

    getstore()
  },[storeCollectionRef])

  const purchasesCollectionRef = collection(db, "purchases")

  const addPurchaseToDataBase = async (userData)=>{
    const dataToPush = {
      session: {
        ...currentState
      },
      cardInfo: {
        ...userData
      }
    }
    try{
      setIsAdding(true)
      const response = await addDoc(purchasesCollectionRef, {...dataToPush})
      if(response){
        setIsAdding(false)
        console.log(response)
        const message = `You are paying <span>$${currentState.asset.price}</span> to obtain the asset <b>${currentState.asset.title}</b>. Your Transaction is being processed and you will be notified in your email <span>${currentState.details.emailAddress}</span> on the status of your transaction. <br /> Thanks for doing business with WGames`

        const type = "success"

        newNotification(message, type)
      }
    } catch(error){
      setIsAdding(false)
      const errorMessage = `An Error Occured while processing your Transaction. Please <a href="https://wa.me/+2347035658853" target="_blank" rel="norefferer">Contact support</a> to have this issue resolved`
      newNotification(errorMessage, "error")
      console.error(error)
    }
  }

  const pay = (userData)=>{
    end()
    addPurchaseToDataBase(userData)
  }

  // const publicKey = "pk_test_9af5a149e78c0ec3d252b3c20abfc25a05f5819f"

  // const componentProps = {
  //   email: currentState?.details?.emailAddress,
  //   amount: Number(currentState.asset.price)*900*100,
  //   metadata: {
  //     details: `User with email address -> ${currentState?.details?.emailAddress} made a purchase of ${currentState.asset.price} for the ${currentState.asset.title}`,
  //     assetTitle: currentState.asset.title,
  //     assetDescription: currentState.asset.description,
  //   },
  //   publicKey,
  //   text: "Make Purchase",
  //   onSuccess: () =>{
  //     end()
  //     addPurchaseToDataBase()
  //     console.log("Adding")
  //     setIsLogging(false)
  //   },
  //   onClose: () => {
  //     end()
  //     setIsLogging(false)
  //     const message = `Purchase cancelled`
  //     const type = "error"
  //     newNotification(message, type)
  //   },
  // }

  const handleEmailChange = (e) =>{
    const property = e.target.name
    const value = e.target.value

    setEmail((prev)=>{
      return ({
        ...prev,
        [property]: value
      })
    })
  }

  useEffect(()=>{
    const prevEmail = localStorage.getItem("email")
    if(prevEmail){
      setCurrentState((prev)=>{
        return ({
          ...prev,
          details: {
            emailAddress: prevEmail,
          },
        })
      })
    }
  },[])

  const initializePurchase = (asset)=>{
    try{
      setMakePurchase(true)
      setCurrentState((prev)=>{
        return ({
          ...prev,
          showing: prev.details.emailAddress ? "confirm-details" : "user-details",
          asset: {
            ...asset
          }
        })
      })
    } catch(error){
      console.error(error)
    }
  }

  const addedEmail = (e)=>{
    e.preventDefault()
    if(email.email === email.confirmEmail){
      try{
        setMakePurchase(true)
        setCurrentState((prev)=>{
          return ({
            ...prev,
            details:{
              emailAddress: email.email,
            },
            showing: "confirm-details",
          })
        })
        localStorage.setItem("email", email.email)
      } catch(error){
        console.error(error)
      }
    } else{
      alert("Email Addresses do not match")
    }
  }

  const editEmail = (e)=>{
    e.preventDefault()
    try{
      setMakePurchase(true)
      setCurrentState((prev)=>{
        return ({
          ...prev,
          showing: "user-details",
        })
      })
    } catch(error){
      console.error(error)
    }
  }

  const emailConfirmed = (e)=>{
    e.preventDefault()
    try{
      setMakePurchase(true)
      setCurrentState((prev)=>{
        return ({
          ...prev,
          showing: "purchase",
        })
      })
    } catch(error){
      console.error(error)
    }
  }

  const gotoFinalStage = ()=>{
    setFinalStage(true)
  }

  const goBack = ()=>{
    try{
      setMakePurchase(true)
      setCurrentState((prev)=>{
        return ({
          ...prev,
          showing: "confirm-details",
        })
      })
    } catch(error){
      console.error(error)
    }
  }

  function getCurrentDate() {
    const options = { month: 'long', day: 'numeric', year: 'numeric' };
    const currentDate = new Date();
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(currentDate);
    return formattedDate;
  }

  useEffect(()=>{
    const date = getCurrentDate()
    setCurrentState((prev)=>{
      return ({
        ...prev,
        date,
      })
    })
  }, [])


  return (
    <>
      {notificationDetails.isOpen && <Notification isOpen={notificationDetails.isOpen} message={notificationDetails.message} type={notificationDetails.type} close={closeNotification} />}
      {isAdding && <div className = "block-all">
        <div className='spinnerx'></div>
        <p>
          Please wait...
        </p>
      </div>}
      {finalStage && <div className='final-stage'>
          <PaymentPage price={currentState.asset.price} email={currentState.details.emailAddress} cancelX={()=>{
            setFinalStage(false) 
          }} gotoFinalStage={gotoFinalStage} pay={pay} />
        </div>
      }
      {makePurchase && <>

        {currentState.showing==="user-details" && <div className='user-details step'>
          <div className='card'>
            <h3>
              Add an Email Address to Continue with this Purchase
            </h3>
            <form onSubmit={addedEmail}>
              <input placeholder='Your Email Address' type='email' value={email.email} onChange={handleEmailChange} name='email' required />
              <input placeholder='Confirm Email Address' type='email' value={email.confirmEmail} onChange={handleEmailChange} name='confirmEmail' required />
              <button className='button'>Done</button>
            </form>
          </div>
        </div>}

        {currentState.showing==="confirm-details" && <div className='confirm-details step'>
          <div className='card'>
            <h3>
              Confirm Details
            </h3>
            <form>
              <input placeholder='Your Email Address' value={currentState.details.emailAddress} type='email' required />
              <div className='btn-holder'>
                <button className='button outline' onClick={editEmail}>Edit</button>
                <button className='button' onClick={emailConfirmed}>Continue</button>
              </div>
            </form>
          </div>
        </div>}

        {currentState.showing==="purchase" && <div className='purchase'>
          <div className='head'>
            <div className='content'>
              <div className='logo'>
                <h2><span>W</span>G</h2>
                <h3>
                  Payment Details
                </h3>
              </div>
              <button className='button outline' onClick={goBack} ><FaArrowLeft /> Back</button>
            </div>
          </div>
          <div className='card'>
            <img src={currentState.asset.imgUrl} alt='' />
            <h2>
              {currentState.asset.title}
            </h2>
            <p>
              {currentState.asset.description}
            </p>
            <div className='actions'>
              <i>Make a payment of <span>${currentState.asset.price}</span> to WGames automated server. And recieve your asset in the email address you provided <span onClick={goBack}>View Email</span> </i>
              <div className='btn-holder'>
                <button className='button' onClick={gotoFinalStage}>
                  Make Payment
                </button>
              </div>
            </div>
          </div>
        </div>}
      </>}

      <div className={makePurchase ? 'backdrop show' : 'backdrop'}>
        <button className='cancel' onClick={()=>{
          end()
        }}>
          <FaTimes />
        </button>
      </div>

      <div className='page shop' onClick={closeMenu}>
        <div className='content'>
          <div className='heading'>
            <h2>
              Our <span>Shop</span>
            </h2>
            <p>
              Browse through our inventory of up-to-date gaming assets, make a purchase, and secure lifetime ownership of the item.
            </p>
          </div>
          <div className='search-holder'>
            <form onSubmit={handleSubmit}>
              <input ref={form} type='search' minLength={3} onChange={handleChange} value={search} placeholder='Search for an Asset...'  />
              <button ref={formBtn} className='search'>
                <FaSearch />
              </button>
            </form>
          </div>
          {hasFetchedStore ? <div className='assets-holder'>
            {filteredAssets.map((asset, index)=>{
              return(
                <div className='asset' key={index}>
                  <div className='price button outline tertiary white'>
                    ${asset.price}
                  </div>
                  <img src={asset.imgUrl} width={200} alt='' />
                  {asset.imgUrl2 && <img className='img2' src={asset.imgUrl2} width={200} alt='' />}
                  <p>
                    {asset.title}
                  </p>
                  <div className='btn-holder'>
                    <button className='button' onClick={()=>{
                      initializePurchase(asset)
                    }}>
                      Buy Now
                    </button>
                  </div>
                </div>
              )
            })}
          </div> : <div className='assets-holder' style={{
            marginTop: "30px",
          }}>
            <div className='spinnerx'></div>
          </div>}
        </div>
      </div>
      <IconsHolder />
    </>
  )
}

export default Shop