import React, { useLayoutEffect, useRef, useState } from 'react'
import { FaPause, FaPlay, FaSpotify } from 'react-icons/fa'
import audioSrc from "../../assets/music.mp3"

const Music = () => {
    const [ playState, setPlayState ] = useState(false)
    const [ isLoaded, setIsLoaded ] = useState(false)

    const audioRef = useRef(null);

    useLayoutEffect(()=>{
        if (playState) {
            audioRef.current.play();
        } else {
            audioRef.current.pause();
        };
    },[playState])

    useLayoutEffect(() => {
        const audio = audioRef.current
        audio.addEventListener('ended', () => {
          setPlayState(false);
        });
    
        audio.addEventListener('canplay', () => {
            setTimeout(()=>{
                setIsLoaded(true);
            }, 1200)
        });

        audio.addEventListener('pause', () => {
          setPlayState(false);
        });
    
        return () => {
          audio.removeEventListener('ended', () => {
            setPlayState(false);
          });
    
          audio.removeEventListener('canplay', () => {
            setIsLoaded(false);
          });

          audio.removeEventListener('pause', () => {
            setPlayState(false); // Handle pause from notifications
          });
        };
    }, []);

  return (
    <>
        <audio ref={audioRef}>
            <source src={audioSrc} type="audio/mpeg" />
        </audio>
        <div className='music-holder'>
            <div className={isLoaded ? 'music loaded' : 'music not-loaded'}>
                <div className='spinner'>
                    <div className='spin'></div>
                </div>
                <div className='details'>
                    <button onClick={()=>{
                        setPlayState(!playState)
                    }} className='play-state'>
                        {!playState ? <FaPlay /> :
                        <FaPause />}
                    </button>
                    <div className='song-title'>
                        <p>
                            Do Better
                        </p>
                        <p>
                            Gunna
                        </p>
                    </div>
                </div>
                <div className='player'>
                    <img className={playState ? 'play' : 'pause'} src='https://th.bing.com/th/id/OIP.1S09easLife47vfvHnwHuQHaHa?pid=ImgDet&rs=1' alt='' />
                    <FaSpotify />
                </div>
            </div>
        </div>
    </>
  )
}

export default Music