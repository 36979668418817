import React, { useEffect, useRef, useState } from 'react'
import Purchase from './Purchase'
import { FaLock, FaPlus, FaTimes } from 'react-icons/fa'
import { FiRefreshCw } from 'react-icons/fi'
import { addDoc, collection, deleteDoc, doc, getDocs, updateDoc } from 'firebase/firestore'
import { db, storage } from '../../api/firebase'
import StoreItem from './StoreItem'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'

const Admin = () => {
    const passwordTxt = "wgames2k23"
    const [password, setPassword] = useState("")
    const [loggedIn, setLoggedIn] = useState("")
    const [hasFetched, setHasFetched] = useState(false)
    const [purchases, setPurchases] = useState([])
    const [error, setError] = useState(false)
    const confirmPassword = (e)=>{
        e.preventDefault()
        if(password === passwordTxt){
            alert("Sign in successful")
            setLoggedIn(true)
        } else{
            alert("Wrong Password")
        }
    }
    const pages = ["store", "purchases"]
    const [currentPage, setCurrentPage] = useState(pages[0])

    const [store, setStore] = useState([])
    const [hasFetchedStore, setHasFetchedStore] = useState(false)
    const [ isAdding, setIsAdding ]  = useState(false)
    const [ deleting, setDeleting ]  = useState(false)
    const [ isWorking, setIsWorking ]  = useState(false)
    const [ isEditing, setIsEditing ]  = useState(false)
    const [ itemDetails, setItemDetails ] = useState({
        title: "",
        description: "",
        price: "",
        id: "",
        imgUrl: ""
    })

    const [ itemThumbnail, setItemThumbnail ] = useState(null)

    const handleChange = (e)=>{
        const {name, value} = e.target
        setItemDetails((prev)=>{
            return ({
                ...prev,
                [name]: value,
            })
        })
    }

    const handleChangeThumbnail = (e)=>{
        try{
            if(e.target.files[0]){
                setItemThumbnail(e.target.files[0])
            }
        } catch(error){
            console.error(error)
        }
    }

    const addItemStart = ()=>{
        setIsAdding(true)
        setIsEditing(false)
    }

    const editItemStart = (item)=>{
        setIsAdding(false)
        setIsEditing(true)
        setItemDetails({...item})
    }

    const refresh = ()=>{
        setHasFetched(false)
        setHasFetchedStore(false)
    }

    const addItem = async(e)=>{
        e.preventDefault()
        
        try{
            console.clear()
            setIsWorking(true)
            const image = itemThumbnail
            const imageRef = ref(storage, `images/${itemDetails.title}`);
    
            await uploadBytes(imageRef, image)
            const imgUrl = await getDownloadURL(ref(storage, `images/${itemDetails.title}`));

            const dataToPush = {
                title: itemDetails.title,
                description: itemDetails.description,
                imgUrl,
                price: itemDetails.price
            }
            await addDoc(storeCollectionRef, {...dataToPush})
            setIsAdding(false)
            setIsWorking(false)
            setItemDetails({
                title: "",
                description: "",
                price: "",
                id: "",
                imgUrl: ""
            })
            refresh()
            alert("Item Added Successfully")
        } catch(error){
            console.error(error)
            setIsWorking(false)
        }
    }

    const editItem = async(e)=>{
        e.preventDefault()
        const id = itemDetails.id
        const itemDoc = doc(db, "store", id)
        const newData = {
            title: itemDetails.title,
            description: itemDetails.description,
            price: itemDetails.price
        }
        try{
            setIsWorking(true)
          await updateDoc(itemDoc, {...newData})
          setIsWorking(false)
          setIsEditing(false)
          setItemDetails({
                title: "",
                description: "",
                price: "",
                id: "",
                imgUrl: ""
            })
            refresh()
            alert("Item Updated Successfully")
        } catch(error){
          console.error(error)
          setIsWorking(false)
        }
    }

    const deleteItem = async(id)=>{
        try{
            setDeleting(true)
            await deleteDoc(doc(db, "store", id));
            setDeleting(false)
            refresh()
            alert("Item Deleted Successfully")
        } catch(error){
            console.error(error)
            setDeleting(false)
        }
    }

    const deleteItemConfirm = (id)=>{
        const confirm = window.confirm("Delete this Item from the store")
        if(confirm){
            deleteItem(id)
        }
    }

    function sortByDate(items) {
        const sortedItems = items.slice().sort((a, b) => {
          const dateA = new Date(a?.currentState?.date);
          const dateB = new Date(b?.currentState?.date);
      
          return dateA - dateB;
        });
      
        return sortedItems;
    }
      

    const intervalRef = useRef(null)

    const purchasesCollectionRef = collection(db, "purchases")
    const storeCollectionRef = collection(db, "store")

    useEffect(()=>{
        if(loggedIn && !error){
            clearInterval(intervalRef.current)

            const getpurchases = async()=>{
                try{
                  const data = await getDocs(purchasesCollectionRef);
                  const filteredData = data.docs.map((doc)=>{
                    return ({
                      ...doc.data(),
                      id: doc.id,
                    })
                  })
                  if(filteredData){
                    const sortedItems = sortByDate(filteredData);
                    setPurchases([...sortedItems])
                    setHasFetched(true)
                    }
                } catch(err){
                    console.error(err)
                    setHasFetched(true)
                    setError(true)
                }
            }

            const getstore = async()=>{
                try{
                  const data = await getDocs(storeCollectionRef);
                  const filteredData = data.docs.map((doc)=>{
                    return ({
                      ...doc.data(),
                      id: doc.id,
                    })
                  })
                  if(filteredData){
                    const sortedItems = sortByDate(filteredData);
                    setStore([...sortedItems])
                    setHasFetchedStore(true)
                }
            } catch(err){
                    console.error(err)
                    setHasFetchedStore(true)
                    setError(true)
                }
            }

            intervalRef.current = setInterval(()=>{
                getpurchases()
                getstore()
            }, 3000)
        } else{
            clearInterval(intervalRef.current)
        }
    },[loggedIn, error, purchasesCollectionRef, storeCollectionRef])

    return (
    <>
        <div className='admin-page'>
            {hasFetched && hasFetchedStore && <button className='refresh-btn' onClick={refresh} style={{
                position: "fixed",
                top: "20px",
                right: "5vw",
                padding: "10px 30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                zIndex: "10",
                backdropFilter: "blur(10px)",
                backgroundColor: "#ccc2",
                boxShadow: "0px 0px 40px #0003",
                color: "#fff",
                fontSize: "20px",
                fontWeight: "600",
                fontFamily: "'Poppins', sans-serif",
                borderRadius: "100px",
                border: "none",
            }}>
                <FiRefreshCw /> Refresh
            </button>}
            <div className='heading'>
                <h2>
                    WGames <p>Admin</p>
                </h2>
                {loggedIn && <div className='btn-holder' style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "20px",
                    marginBottom: "20px",
                }}>
                    {pages.map((page, index)=>{
                        return (
                            <button key={index} style={{
                                backgroundColor: page === currentPage ? "#fff" : "#fff0",
                                color: page === currentPage ? "#000" : "#fff",
                                border: page === currentPage ? "2px solid #fff0" : "2px solid #fff",
                                transition: "all 0.3s ease",
                                padding: "10px 15px",
                                borderRadius: "10px",
                                textTransform: "capitalize",
                                fontFamily: "'Poppins', sans-serif",
                                fontSize: "18px",
                                fontWeight: "600",
                                transform: page === currentPage ? "scale(1.1)" : "scale(1)",
                                minWidth: "150px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "10px",
                            }} onClick={()=>{
                                setCurrentPage(page)
                            }}>
                                {page}
                                {page === currentPage && <>
                                    {hasFetchedStore ? <>
                                        {currentPage === "store" && <span style={{fontWeight: "400", fontSize: "15px",}}>{`(${store?.length})`}</span>}
                                    </> : <>
                                        {"..."}
                                    </>}
                                        
                                    {hasFetched ? <>
                                        {currentPage === "purchases" && <span style={{fontWeight: "400", fontSize: "15px",}}>{`(${purchases?.length})`}</span>}
                                    </> : <>
                                        {"..."}
                                    </>}
                                </>}
                            </button>
                        )
                    })}
                </div>}
            </div>
            {!loggedIn ? <div className='password'>
                <p>
                    Verify ownership to proceed <FaLock />
                </p>
                <form onSubmit={confirmPassword}>
                    <input value={password} onChange={(e)=>{
                        setPassword(e.target.value)
                    }} type='password' name='password' minLength={4} />
                    <button>Verify</button>
                </form>
            </div> :
            <>
                {(isAdding || isEditing) && <div className='add-itemx'>
                    <div className='close' onClick={()=>{
                        setIsAdding(false)
                        setIsEditing(false)
                        setItemDetails(()=>{
                            return ({
                                title: "",
                                description: "",
                                price: "",
                                id: "",
                                imgUrl: ""
                            })
                        })
                    }}>
                        <FaTimes /> Close
                    </div>
                    {isAdding && <h2>Add Item</h2>}
                    {isEditing && <h2>Edit Item</h2>}
                    {isAdding && <form onSubmit={addItem} style={{
                        transition: "all 0.5s ease",
                        opacity: isWorking ? "0.4" : "1",
                        pointerEvents: isWorking ? "none" : "all"
                    }}> 
                        <input required placeholder='Title' name='title' onChange={handleChange} value={itemDetails.title} />
                        <input required placeholder='Description' name='description' onChange={handleChange} value={itemDetails.description} />
                        <input required placeholder='Price (in USD)' name='price' onChange={handleChange} value={itemDetails.price} />
                        <input required placeholder='Thumbnail' type='file' name='image' onChange={handleChangeThumbnail} />
                        <button className='button'>
                            {isWorking ? "Please wait..." : "Add Item"}
                        </button>
                    </form>}
                    {isEditing && <form onSubmit={editItem} style={{
                        transition: "all 0.5s ease",
                        opacity: isWorking ? "0.4" : "1",
                        pointerEvents: isWorking ? "none" : "all"
                    }}>
                        <div className='current-img'>
                            <p>
                                Product Thumbnail
                            </p>
                            <img src={itemDetails.imgUrl} alt='' />
                        </div>
                        <input required placeholder='Title' name='title' onChange={handleChange} value={itemDetails.title} />
                        <input required placeholder='Description' name='description' onChange={handleChange} value={itemDetails.description} />
                        <input required placeholder='Price (in USD)' name='price' onChange={handleChange} value={itemDetails.price} />
                        {/* <input required placeholder='Thumbnail' type='file' name='image' onChange={handleChangeThumbnail} /> */}
                        <button className='button'>
                            {isWorking ? "Updaing..." : "Save changes"}
                        </button>
                    </form>}
                </div>}

                {error ? <><p>An Error Occured</p></> : <>
                {currentPage === "purchases" && <>
                    {hasFetched ? <>{purchases?.length < 1 ? <p>No Recorded Purchases Yet</p> : <div className='purchases-holder'>
                        {purchases.map((purchase, index)=>{
                            return (
                                <Purchase purchase={purchase} key={index} />
                            )
                        })}
                    </div>}</> : <div className='center-big'>
                        <div className='spinnerx'></div>
                    </div>}
                </>}
                {currentPage === "store" && <>
                    {hasFetchedStore ? <>{store?.length < 1 ? <p>No Items In Store</p> : <><div className='store-holder' style={{
                        transition: "all 0.5s ease",
                        opacity: deleting ? "0.4" : "1",
                        pointerEvents: deleting ? "none" : "all",
                    }}>
                        {store.map((data, index)=>{
                            return (
                                <StoreItem editItem={editItemStart} deleteItem={deleteItemConfirm} data={data} key={index} />
                            )
                        })}
                    </div>
                    </>}
                    <div className='add-item' style={{
                        marginTop: "30px"
                    }}>
                        <button className='button' onClick={addItemStart}>
                            <FaPlus /> Add Item to Store
                        </button>
                    </div>
                    </> : <div className='center-big'>
                        <div className='spinnerx'></div>
                    </div>}
                </>}
                </>}
            </>}
        </div>
    </>
  )
}

export default Admin