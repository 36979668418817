import React, { useEffect, useLayoutEffect, useReducer, useState } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router'
import Home from './pages/home/Home'
import Header from './components/header/Header'
import Menu from './components/Menu/Menu'
import AppContext from './appContext/appContext'
import "./styles/index.scss"
import Shop from './pages/shop/Shop'
import Gallery from './pages/gallery/Gallery'
import AnimatedCursor from 'react-animated-cursor'
import Music from './components/music/Music'
import Admin from './pages/admin/Admin'

const App = () => {
  const initialState = {
    isLoggedIn: false,
    token: "",
    exchangeRate: 1000,
    user:{
      userName: "",
      firstName: "",
      lastName: "",
      emailAddress: "",
      imgUrl: "",
      isAdmin: false,
      userId: "",
      purchased: []
    },
    allUsers:[],
    Store: [],
    isLoading: false,
  }

  const navigate = useNavigate()

  const updateAppState = (state, action)=>{
    const actionType = action.type
    const data = action.data

    switch (actionType){
      case ("logged-in"):
        const userData = data
        state.functions.handleNotification("Login Successful", "success")
        localStorage.setItem("token", userData.token)
        localStorage.setItem("userId", userData.userId)
        return {
          ...state,
          isLoggedIn: true,
          isChecking: false,
          isCheckingPlan: true,
          token: userData.token,
          user: {
          ...state.user,
          }
        }

      case ("loaded"):
        return{
          ...state,
          isLoading: false,
        }

      case ("got-current-user"):
      const user = data.user
      console.log(user)
      const token = localStorage.getItem("token")
      
      return {
        ...state,
        isLoggedIn: true,
        token: token,
        isChecking: false,
        isCheckingPlan: true,
        user: {
          ...state.user,
        }
      }

      case ("got-users"):
        const allUsers = data
        return {
          ...state,
          allUsers: [
            ...allUsers
          ]
        }

      case ("navigate"):
        const path = data
        navigate(path)
        return {
          ...state,
        }

      default:
        return state
      
    }
  }  

  const [appState, dispatch] = useReducer(updateAppState, initialState)

  useEffect(()=>{
    setTimeout(()=>{
      dispatch({type: "loaded"})
    }, 500)
  },[])

  const location = useLocation()

  const [showCursor, setShowCursor] = useState(true)

  useLayoutEffect(()=>{
    setShowCursor(true)
    window.addEventListener("touchstart", ()=>{
      setShowCursor(false)
    })
  },[location.pathname])

  const [showMenu, setShowMenu] = useState(false)

  const toggleMenu = ()=>{
    setShowMenu(!showMenu)
  }

  const closeMenu = ()=>{
    setShowMenu(false)
  }

  return (
    <AppContext.Provider value={appState}>
      <div className={`app ${showMenu ? "show-menu" : ""}`}>
        <Header toggleMenu={toggleMenu} />
        <Menu showMenu={showMenu} closeMenu={closeMenu} />

        <Music />
        {showCursor && <AnimatedCursor
          innerSize={10}
          outerSize={80}
          color='250, 48, 88'
          outerAlpha={0.2}
          innerScale={0.7}
          outerScale={1.5}
          clickables={[
            'a',
            'input[type="text"]',
            'input[type="email"]',
            'input[type="number"]',
            'input[type="submit"]',
            'input[type="image"]',
            'label[for]',
            'select',
            'textarea',
            'button',
            '.button',
            '.btn',
            '.link'
          ]}
        />}

        <Routes>
          <Route path='/' element={<Home closeMenu={closeMenu} />} />
          <Route path='/shop' element={<Shop  closeMenu={closeMenu} />} />
          <Route path='/gallery' element={<Gallery  closeMenu={closeMenu} />} />
          <Route path='/admin' element={<Admin  closeMenu={closeMenu} />} />
          <Route path='/*' element={<Home closeMenu={closeMenu} />} />
        </Routes>
      </div>
    </AppContext.Provider>
  )
}

export default App