import React from 'react'
import {NavLink} from 'react-router-dom'

const Menu = ({closeMenu, showMenu}) => {
  const links = [
    {
      name: "Home",
      to: "/"
    },
    {
      name: "Gallery",
      to: "/gallery"
    },
    {
      name: "Shop",
      to: "/shop"
    },
    {
      name: "NFTs",
      to: ""
    },
  ]
  return (
    <div className='pages-links-holder'>
      <div className='links'>
        {
          links.map((link, index)=>{
            return (
              <NavLink className={link.to ? "" : "hide"} style={showMenu ? {
                transitionDelay: `${(index+1)/10}s`
              } : {
                transitionDelay: `0s`
              }} to={link.to ? link.to : "/axax"} key={index} onClick={closeMenu}>{link.name}</NavLink>
            )
          })
        }
      </div>
    </div>
  )
}

export default Menu