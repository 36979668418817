import React from 'react'
import { FaEdit, FaRegTrashAlt } from 'react-icons/fa'

const StoreItem = ({data, editItem, deleteItem}) => {
  return (
    <>
        <div className='store-item'>
            <img src={data.imgUrl} alt='' width={50} />
            <div className='details'>
                <div className='title'>{data.title ? data.title : "..."}</div>
                <div className='price'>{data.price ? `$${data.price}` : "..."}</div>
                <div className='actions'>
                    <button className='action-btn' onClick={()=>{
                        editItem(data)
                    }}>
                        <FaEdit />
                    </button>
                    <button className='action-btn' onClick={()=>{
                        deleteItem(data.id)
                    }}>
                        <FaRegTrashAlt />
                    </button>
                </div>
            </div>
        </div>
    </>
  )
}

export default StoreItem