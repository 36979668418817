import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCLNKsaIfT7n5KXLL7DP7yOEWjx36yegRA",
  authDomain: "wgames-template.firebaseapp.com",
  projectId: "wgames-template",
  storageBucket: "wgames-template.appspot.com",
  messagingSenderId: "924430536243",
  appId: "1:924430536243:web:530318f8907fea4f479d46"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);