import React, { useEffect, useState } from 'react'
import { FaRegCheckCircle, FaRegQuestionCircle, FaTimes } from 'react-icons/fa'
import { BiErrorAlt } from 'react-icons/bi'

const Notification = ({message, isOpen, type, close, actions}) => {
    const [typex, setTypex] = useState(null)
    useEffect(()=>{
        setTypex(null)
        setTimeout(()=>{
            setTypex(type)
        }, 2700)
    }, [type])
    return (
    <>
        <div className={`notification-holder ${isOpen ? "show" : ""} ${type}`}>
            <div className='card'>
                {typex === "success" && <h3 className={`type ${typex}`}><FaRegCheckCircle /> Success</h3>}
                {typex === "normal" && <h3 className={`type ${typex}`}><FaRegQuestionCircle /> Loading</h3>}
                {!typex && <h3 className={`type ${typex}`}><FaRegQuestionCircle /> Loading</h3>}
                {typex === "error" && <h3 className={`type ${typex}`}><BiErrorAlt /> Error</h3>}
                {typex ? <p dangerouslySetInnerHTML={{__html: message? message : "Successful"}}>
                </p> : <div className="center">
                    <div className='spinnerx alt-one small'></div>
                </div>}
                {actions && <div className='actions'>
                    {actions.map((action, index)=>{
                        return (
                            <button className='button outline' onClick={action.onClick} key={index}>
                                {action.title}
                            </button>
                        )
                    })}
                </div>}
                <button className="close-btn" onClick={close}>
                    <FaTimes />
                </button>
            </div>
        </div>
    </>
  )
}

export default Notification