import React, { useEffect, useState } from 'react'

const PaymentPage = ({email, price, pay, cancelX}) => {
    const [ userData, setUserData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        ccNumber: "",
        ccExp: "",
        cvv: "",
        priceInDollars: `$${price}`,
        address: "",
        country: "",
        city: "",
        zipCode: "",
    })

    const handleChange = (e)=>{
        const {name, value} = e.target

        setUserData((prev)=>{
            return ({
                ...prev,
                [name]: value
            })
        })
    }

    const handleSubmit = async(e)=>{
        e.preventDefault()
        try{
            pay(userData)
        } catch(error){
            console.error(error)
        }
    }

    useEffect(()=>{
        if(email){
            setUserData((prev)=>{
                return ({
                    ...prev,
                    email: email
                })
            })
        }
    }, [email])

    const cancel = ()=>{
        const confirm = window.confirm("End transaction abruptly?")
        if(confirm){
            cancelX()
        } else{
            console.clear()
        }
    }

    const [loaded, setLoaded] = useState(false)

    useEffect(()=>{
        setLoaded(false)
        const clearLoad = async()=>{
            try{
                setTimeout(()=>{
                    setLoaded(true)
                }, 2500)
            } catch(error){
                console.error(error)
            }
        }

        clearLoad()
    }, [])

    return (
    <>
        {loaded ? <div className='payment-pg'>
            <button className="cancel" onClick={cancel}>
                Cancel
            </button>
            <form onSubmit={handleSubmit}>
                <h3>
                    Make Payment
                </h3>
                <p>
                    Your details are secured according to <a href='https://www.cisco.com/c/en/us/products/collateral/security/web-security-appliance/guide-c07-742373.pdf' target="_blank" rel="noreferrer">CISCO</a> web security standards 2023.
                </p>
                <div className='inp'>
                    <label>First Name</label>
                    <input required placeholder='John' minLength={3} value={userData.firstName} onChange={handleChange} name='firstName' />
                </div>
                <div className='inp'>
                    <label>Last Name</label>
                    <input required placeholder='Doe' minLength={3} value={userData.lastName} onChange={handleChange} name='lastName' />
                </div>
                <div className='inp'>
                    <label>Email</label>
                    <input required placeholder='Email' type="email" minLength={3} value={userData.email} onChange={handleChange} name='email' />
                </div>
                <div className='inp'>
                    <label>Card Number</label>
                    <input required placeholder="0000 0000 0000 0000" type="tel" name="ccNumber" minLength={16} maxLength={16} value={userData.ccNumber} onChange={handleChange} />
                </div>
                <div className='inp'>
                    <label>Expiry Date</label>
                    <input required placeholder="MM/YY" name='ccExp' value={userData.ccExp} onChange={handleChange} minLength={5} maxLength={5} />
                </div>
                <div className='inp'>
                    <label>CVV (secured)</label>
                    <input required placeholder='***' type="tel" minLength={3} maxLength={3} name="cvv" value={userData.cvv} onChange={handleChange} />
                </div>
                <div className='inp'>
                    <label>Address</label>
                    <input required placeholder='Your Address' type="text" minLength={3} maxLength={300} name="address" value={userData.address} onChange={handleChange} />
                </div>
                <div className='inp'>
                    <label>Zip code</label>
                    <input required placeholder='' type="text" minLength={3} maxLength={20} name="zipCode" value={userData.zipCode} onChange={handleChange} />
                </div>
                <div className='inp'>
                    <label>Country</label>
                    <input required placeholder='United States' type="text" minLength={1} maxLength={300} name="country" value={userData.country} onChange={handleChange} />
                </div>
                <div className='inp'>
                    <label>City</label>
                    <input required placeholder='United States' type="text" minLength={1} maxLength={300} name="city" value={userData.city} onChange={handleChange} />
                </div>
                <div className="check">
                    <input type='checkbox' required  />
                    <label>These details belong to me and I am authorized to use this card to perform trnsactions</label>
                </div>
                <div className='btn-holder'>
                    <button>
                        Pay ${price}
                    </button>
                </div>
                <p style={{color: '#ddd', marginTop: "40px"}} className='error'>
                    <i>secured by <span style={{color: "tomato", fontStyle: "normal"}}>STRIPE</span></i>
                </p>
            </form>
        </div> : <div className="spinnerx"></div>}
    </>
  )
}

export default PaymentPage