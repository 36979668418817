import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { FaChevronDown, FaChevronUp, FaGlobeAmericas, FaPlus, FaTimes} from 'react-icons/fa'
import squadra from "../../assets/squadra-digitals.jpg"
import emotionalIdeas from "../../assets/emotional-ideas.jpg"
import { HiSparkles } from 'react-icons/hi2'
import img1 from "../../assets/img-i.jpg"
import img2 from "../../assets/img-ii.jpg"
import img3 from "../../assets/img-iii.jpg"
import img4 from "../../assets/img-iv.jpg"
import img5 from "../../assets/img-v.jpg"
import img6 from "../../assets/img-vi.jpg"
import img7 from "../../assets/img-vii.jpg"
import img8 from "../../assets/img-viii.jpg"
import services from "../../assets/services.png"
import { useNavigate } from 'react-router'
import IconsHolder from '../../components/iconsHolder/IconsHolder'

const Home = ({closeMenu}) => {
  const [currentImg, setCurrentImg] = useState({
    url: img5,
    title: "game",
    type: "img" 
  })

  const [changing, setChanging] = useState(true)

  useEffect(()=>{
    const images = [ img2, img3, img4, img5, img6, img7, img8, img1]
    const len = images.length+1
    const interval = setInterval(()=>{
      const randomNo = Math.random()*len
      const floor = Math.floor(randomNo)
      setChanging(true)
      setTimeout(()=>{
        setCurrentImg((prev)=>{
          return ({
            ...prev,
            url: images[floor]
          })
        })
      },700)
      setTimeout(()=>{
        setChanging(false)
      },1500)
    }, 9000)

    return ()=>{
      clearInterval(interval)
    }
  },[])

  const sections = ["intro", "all", "experience"]

  const infoHolder = useRef()
  
  const [currentSection, setCurrentSection] = useState(sections[0])
  
  const [showXP, setShowXP] = useState(false)
  
  useLayoutEffect(()=>{
    infoHolder.current.scrollTop = 0
  },[showXP])
  
  const navigate = useNavigate()
  return (
    <>
      <div className={showXP ? 'big-xp show' : 'big-xp'} onClick={()=>{
        navigate("/")
      }}>
        <div className='left-bg'></div>
        <div className='right-bg'></div>
        <div className='infox'>
          <button className='btn' onClick={()=>{
            setShowXP(false)
          }}>
            <FaTimes /> Back
          </button>
          <div className='info-holder' ref={infoHolder}>
            <h2>We've partnered with <span>Top Brands</span> across the Globe <HiSparkles /> </h2>
            <div className='companies'>
              <div className='company'>
                <img src='https://www.freepnglogos.com/uploads/toyota-logo-png/toyota-logo-photo-12.png' alt='' />
                <p>2023 <span>Toyota</span></p>
              </div>
            
              <div className='company'>
                <img src={emotionalIdeas} alt='' />
                <p>2023 <span>Emotional Ideas</span></p>
              </div>

              <div className='company'>
                <img src={squadra} alt='' />
                <p>2022 <span>Squadra Digital</span></p>
              </div>
            
            </div>
            <p> ©2024 WGames solutions</p>
          </div>
        </div>
      </div>
      <div className={`page home only`} onClick={closeMenu}>
        <div className='before'>
          <div className='holder'>
            <div className='cprt'>
              © 2023 WGames solutions
            </div>
            <div className='links'>
              <img src={services} alt='' />
            </div>
          </div>
        </div>
        <div className={`content ${currentSection}`}>
          <div className='headline'>
            <h1>
              <span>W</span>Games
            </h1>
            <p>
              We specialize in crafting contemporary games featuring imaginative gameplay and compelling narratives.
            </p>
            <div className='btn-holder'>
              <button className='button' onClick={()=>{
                setCurrentSection(sections[1])
              }}>
                Learn More <FaChevronDown className='border-round svg' />
              </button>
            </div>
          </div>
          <div className='about'>
            <button className='btn'  onClick={()=>{
                setCurrentSection(sections[0])
              }}>
              <FaChevronUp />
            </button>
            <div className='about-hold'>
              <h2>About <span>W</span>Games</h2>
              <p>
                Welcome to WGames, where imagination meets innovation in the world of gaming! As a leading game development agency, we are dedicated to crafting immersive and unforgettable gaming experiences that captivate players and push the boundaries of interactive entertainment.
              </p>
            </div>
            <button className='button outline white'  onClick={()=>{
                setCurrentSection(sections[2])
              }}>
              Experience <FaGlobeAmericas />
            </button>
          </div>
          <div className='xp'>
            <button className='btn'  onClick={()=>{
                setCurrentSection(sections[1])
              }}>
              <FaChevronUp />
            </button>
            <div className='about-hold'>
              <h2>Our <span>Experience</span></h2>
              <p>
                Top notch value crafted from Ample years of experience in delivering mind-blowing services at the top tier of the industry.
              </p>
            </div>
            <button className='button outline white' onClick={()=>{
              setShowXP(true)
            }}>
              Check it Out <FaPlus />
            </button>
          </div>
          <div className='carousel' style={changing ? {
            transition: "all 1s ease",
            opacity: 0,
            transform: "scale(1.4)",
          } : {
            transition: "transform 15s ease, opacity 1s ease",
            opacity: 0.7,
            transform: "scale(1)",
          }}>
            <img src={currentImg.url ? currentImg.url : "https://th.bing.com/th/id/OIP.EzVlxTY4p8Yzyj2D6T-exQAAAA?pid=ImgDet&rs=1"} alt='carousel-img' />
          </div>
        </div>
      </div>
      <IconsHolder isHome={true} openAbout={()=>{
        setCurrentSection(sections[1])
      }} />
      <div className='back i'></div>
      <div className='back ii'></div>
    </>
  )
}

export default Home