import React, { useLayoutEffect, useRef, useState } from 'react'
import {BiFullscreen} from 'react-icons/bi'
import img1 from "../../assets/img-i.jpg"
import img2 from "../../assets/img-ii.jpg"
import img3 from "../../assets/img-iii.jpg"
import img4 from "../../assets/img-iv.jpg"
import img5 from "../../assets/img-v.jpg"
import img6 from "../../assets/img-vi.jpg"
import img7 from "../../assets/img-vii.jpg"
import img8 from "../../assets/img-viii.jpg"
import img9 from "../../assets/img-ix.jpg"
import img11 from "../../assets/img-xi.jpg"
import { FaChevronLeft, FaChevronRight, FaTimes } from 'react-icons/fa'
import {useNavigate} from "react-router"
import IconsHolder from '../../components/iconsHolder/IconsHolder'

const Gallery = ({closeMenu}) => {
  const imagesX = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img11]

  const [bigView,setBigView] = useState({
    show: false,
    image: "",
  })

  const showBigView = (image)=>{
    setBigView({
      show: true,
      image: image
    })
  }

  const closeBigView = ()=>{
    setBigView((prev)=>{
      return ({
        ...prev,
        show: false,
      })
    })
    
    setTimeout(()=>{
      setBigView({
        show: false,
        image: null,
      })
    },700)
  }

  const containerRef = useRef(null)

  useLayoutEffect(()=>{
    const container = document.getElementById("swiper-container")
    const scrollLeftButton = document.getElementById('scrollLeft');
    const scrollRightButton = document.getElementById('scrollRight');

    scrollLeftButton.addEventListener('click', () => {
      container.scrollLeft -= 100; // Adjust the scroll amount as needed
    });

    scrollRightButton.addEventListener('click', () => {
      container.scrollLeft += 100; // Adjust the scroll amount as needed
    });

  },[])

  const navigate = useNavigate()

  return (
    <>
      <div className={`big-view ${bigView.show ? "show" : ""}`}>
        <div className='close-me' onClick={closeBigView}>
          <FaTimes /> Exit
        </div>
        <img src={bigView.image} alt='big-view' />
      </div>
      <div className='page home gallery' onClick={closeMenu}>
        <div className='content'>
          <div className='headline'>
            <h2>
              Our <span>Gallery</span>
            </h2>
            <p>
              Explore Our Gaming Project Gallery. Visit our Shop to purchase an asset for your project
            </p>
            <button className='button' onClick={()=>{
              navigate("/shop")
            }}>Visit Shop</button>
          </div>
          <div className='swiper' ref={containerRef} id='swiper-container'>
            {imagesX.map((image, index)=>{
              return (
                <div className='img-holder' key={index}>
                  <img src={image} alt='gallery' />
                  <button className='open' onClick={()=>{
                    showBigView(image)
                  }}>
                    <BiFullscreen />
                  </button>
                </div>
              )
            })}
            </div>
            <div className='btn-holder'>
              <button id='scrollLeft' className='button outline'>
                <FaChevronLeft />
              </button>
              <button id='scrollRight' className='button outline'>
                <FaChevronRight />
              </button>
          </div>
        </div>
      </div>
      <div className='back i'></div>
      <div className='back ii'></div>
      <IconsHolder />
    </>
  )
}

export default Gallery