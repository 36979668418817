import React from 'react'
import { useLocation } from 'react-router'
import { useNavigate } from 'react-router'
import logo from '../../assets/logo192.png'
import { FaBars, FaTimes } from 'react-icons/fa'

const Header = ({toggleMenu}) => {
  const location = useLocation()
  const navigate = useNavigate()
  return (
    <>
      <div className={`header ${location.pathname.includes("/shop")?"blur":""}  ${location.pathname.includes("/gallery")?"blur":""}`}>
        <div className='content'>
          <div className='logo' onClick={()=>{
            navigate("/")
          }}>
            {/* <h2>
              <span>W</span>G
            </h2> */}
            <img src={logo} alt="" width={100} />
          </div>
          <div className='menu' onClick={toggleMenu}>
            {/* <div className='bar'></div> */}
            <FaBars />
            <FaTimes />
          </div>
        </div>
      </div>
    </>
  )
}

export default Header