import React from 'react'
import { FaHome, FaQuestion, FaShoppingBasket } from 'react-icons/fa'
import { useLocation, useNavigate } from 'react-router'

const IconsHolder = ({openAbout, isHome}) => {
    const navigate = useNavigate()
    const location = useLocation()
  return (
    <>
        <div className='icon-holder'>
            {location.pathname.includes("/shop") || location.pathname.includes("/gallery")  ? <button className='btn' onClick={()=>{
                navigate("/")
            }}>
            <FaHome /> <span>Home</span>
            </button> : <button className='btn' onClick={()=>{
                navigate("/shop")
            }}>
            <FaShoppingBasket /> <span>Shop</span>
            </button>}
            {isHome && <button className='btn' onClick={()=>{
                openAbout()
            }}>
            <FaQuestion />
            </button>}
            {/* <button className='btn disabled'>
                Contact us <FaArrowRight />
            </button> */}
        </div>
    </>
  )
}

export default IconsHolder